<template>
  <div class="player-zoom">
    <div :class="`button-zoom ${themeName}`" @click="$emit('click')">
      <img v-if="$vuetify.theme.dark" src="@/assets/Zoom_Filled_16px_Icon.svg" width="14px" />
      <img v-else src="@/assets/Zoom_Filled_16px_Icon-black.svg" width="14px" />
      <span>{{ zoomRatio }}%</span>
    </div>
  </div>
</template>

<script>
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  props: {
    zoom: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    zoomRatio() {
      return (this.zoom * 100).toFixed(0)
    },
  },
}
</script>

<style lang="scss" scoped>
.player-zoom {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 120px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.1s linear;

  &:hover {
    opacity: 1;
  }
}

.button-zoom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 30px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  color: var(--f-text-black);
  border: 1px solid var(--f-light-gray-2);
  background-color: var(--f-text-white);
  &.dark {
    color: var(--f-text-white-high);
    border: 1px solid var(--f-text-white-high);
    background-color: var(--f-bg-high);
  }

  img {
    margin-right: 8px;
  }
}
</style>
