<template>
  <div @mousedown="$emit('mousedown', $event)" :style="contextMenuStyle">
    <slot></slot>
  </div>
</template>

<script>
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  /**
   * emits
   * mousedown
   * close
   */
  props: {
    data: {
      type: Object,
      default: { x: 0, y: 0, display: false },
    },
    width: {
      type: Number,
      default: '155',
    },
  },
  computed: {
    contextMenuStyle() {
      return `position: fixed;
      left: ${this.data.x}px;
      top: ${this.data.y}px;
      display: ${this.data.display ? 'inline-block' : 'none'};
      width: ${this.width}px;
      height: 30px;
      ${this.$vuetify.theme.dark ? 'color: var(--f-text-white-high);' : 'color: var(--f-text-black);'}
      ${this.$vuetify.theme.dark ? 'background-color: var(--f-bg-high);' : 'background-color: var(--f-text-white);'}
      ${this.$vuetify.theme.dark ? 'box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);' : 'box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);'}
      border-radius: 8px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      z-index:2;
      `
    },
  },
}
</script>

<style></style>
