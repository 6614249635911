<template>
  <div :style="loadingStyle" class="loading-bg">
    <img src="@/assets/Rosary-1.5s-200px.svg" class="loading" />
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    loadingStyle() {
      if (this.loading) {
        return {
          visibility: 'visible',
          zIndex: 5,
        }
      } else {
        return {
          visibility: 'hidden',
          zIndex: -1,
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.loading-bg {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--f-light-gray-1);
}

.loading {
  width: 80px;
  height: 80px;
}
</style>
